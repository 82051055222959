import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchQuestions(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/questions', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addQuestion(ctx, { payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/questions/create', payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchQuestion(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/questions/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateQuestion(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/questions/${id}/update`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteQuestion(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/questions/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}